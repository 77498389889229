<template>
	<div class="container mt-5">
		<div class="row mb-4">
			<div class="col">
				<div class="card shadow-sm">
					<div class="card-header bg-primary text-white">
						<h5 class="mb-0">Order Details</h5>
					</div>
					<div class="card-body">
						<p class="card-text">View and manage your order</p>
						<div class="d-flex justify-content-between">
							<button class="btn btn-sm btn-outline-primary" @click="fetchOrder">Refresh Order</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="order" class="order-card">
			<div class="card shadow-sm mb-4">
				<div class="card-header d-flex justify-content-between align-items-center py-3 bg-primary-gradient cursor-pointer"
					@click="toggleOrderDetails">
					<div class="d-flex flex-column">
						<h5 class="mb-1 fw-semibold">Order #{{ order.order_id }}</h5>
						<div class="d-flex align-items-center gap-2">
							<span class="status-indicator"
								:style="{ backgroundColor: statusColors[order.status] }"></span>
							<span class="badge bg-dark text-white me-2">{{ order.status }}</span>
							<span class="text-white-75">
								<i class="bi bi-calendar-event me-1"></i>
								Expected Completion Date: {{ formatDate(order.estimated_completion_date) }}
							</span>
						</div>
					</div>
					<div class="d-flex gap-2">
						<button v-if="order.status === 'Awaiting Approval'"
							class="btn btn-sm btn-success text-uppercase fw-medium px-3"
							style="background-color: #03a9f4;" @click.stop="changeOrderStatus('Accepted')">
							Approve
						</button>
						<button v-if="order.status === 'Accepted'"
							class="btn btn-sm btn-warning text-uppercase fw-medium px-3"
							style="background-color: #4caf50;" @click.stop="changeOrderStatus('In Production')">
							In Production
						</button>
						<button v-if="order.status === 'In Production'"
							class="btn btn-sm btn-danger text-uppercase fw-medium px-3"
							style="background-color: #f44336;" @click.stop="changeOrderStatus('Ready to Collect')">
							Mark Ready to Collect
						</button>
						<button v-if="order.status === 'Ready to Collect'"
							class="btn btn-sm btn-danger text-uppercase fw-medium px-3"
							style="background-color: #6f42c1;" @click.stop="changeOrderStatus('Completed')">
							Mark as Completed
						</button>
					</div>
				</div>

				<div v-if="order.showDetails" class="card-body bg-light-subtle">
					<div class="row mb-4">
						<div class="col-md-6">
							<p class="mb-1"><span class="text-secondary">Placed by:</span> {{ order.admin_user_email }}
							</p>
							<p class="mb-1"><span class="text-secondary">Fulfilled by:</span> {{
								order.fulfillment_emails[0] }}</p>
							<p class="mb-1"><span class="text-secondary">Created:</span> {{ formatDate(order.order_date)
							}}</p>
						</div>
						<div class="col-md-6 text-md-end">
							<p class="h5 mb-2">Total: £{{ order.total_price }}</p>
							<span class="badge bg-primary">Fulfillment Email Sent: {{ order.email_sent ? 'Yes' : 'No'
							}}</span>
						</div>
					</div>

					<div class="mb-4">
						<h6 class="mb-3 fw-semibold text-primary">Products</h6>
						<div v-for="item in order.order_items" :key="item.order_item_id"
							class="product-item bg-white p-3 rounded-2 shadow-sm mb-3">
							<div class="row">
								<div class="col-md-4">
									<p class="mb-1 fw-medium">#{{ item.product_id }} {{ item.product.name }}</p>
									<p class="text-muted small mb-0">{{ item.product.description }}</p>
								</div>
								<div class="col-md-8">
									<div class="row text-end">
										<div class="col-3"><span class="text-secondary">Qty:</span> {{ item.quantity }}
										</div>
										<div class="col-3">Item Price: £{{ item.price }}</div>
										<div class="col-3">Subtotal: £{{ item.subtotal }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div v-if="order.comment" class="alert alert-info mb-4">
						<p class="mb-0"><strong>Comments:</strong> {{ order.comment }}</p>
					</div>

					<div class="site-info bg-white p-3 rounded-2 shadow-sm">
						<h6 class="border-bottom pb-2 mb-3 fw-semibold">Site Details</h6>
						<div class="row">
							<div class="col-md-3">
								<p class="mb-1"><span class="text-secondary">Reference:</span> {{ order.site.reference
								}}</p>
							</div>
							<div class="col-md-3">
								<p class="mb-1"><span class="text-secondary">Name:</span> {{ order.site.name }}</p>
							</div>
							<div class="col-md-3">
								<p class="mb-1"><span class="text-secondary">Max Stay:</span> {{ order.site.max_stay }}
									mins</p>
							</div>
							<div class="col-md-3">
								<p class="mb-1"><span class="text-secondary">No Return:</span> {{ order.site.no_return
								}} mins ({{ order.site.no_return / 60 }} hrs)</p>
							</div>
						</div>
					</div>

					<div class="d-flex justify-content-end gap-2 mt-4">
						<button class="btn btn-primary btn-sm" @click="printJobsheet">
							<i class="bi bi-printer me-2"></i>Print Jobsheet
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';
import jsPDF from 'jspdf';
import varslogo from '../../../public/images/vars_logo_black.png';

export default {
	name: 'Order',
	data() {
		return {
			order: [],
			loading: false,
			orderId: null
		};
	},
	computed: {
		statusColors() {
			return {
				'Awaiting Approval': '#FFFF00', // Yellow
				'Accepted': '#03a9f4', // Light Blue
				'In Production': '#4caf50', // Light Green
				'Ready to Collect': '#f44336', // Light Red
				'Completed': '#6f42c1' // Purple
			};
		},
	},
	mounted() {
		this.orderId = this.$route.params.id;
		this.fetchOrder();
	},
	methods: {
		fetchOrder() {
			this.loading = true;
			axios.get(`https://api.varsanpr.com/api/ordering-pos/orders-pos/${this.orderId}`, {
				headers: authHeader()
			})
				.then(response => {
					this.order = response.data.data;
					this.loading = false;
				})
				.catch(error => {
					console.log(error);
					this.loading = false;
					this.$router.push('/ordering-pos');
				});
		},
		formatDate(date) {
			const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
			return new Date(date).toLocaleDateString(undefined, options);
		},
		toggleOrderDetails() {
			this.order.showDetails = !this.order.showDetails;
		},
		changeOrderStatus(newStatus) {
			axios.put(`https://api.varsanpr.com/api/ordering-pos/orders-pos/${this.orderId}`, { status: newStatus }, {
				headers: authHeader()
			})
				.then(() => {
					this.fetchOrder();
				})
				.catch(error => {
					console.log(error);
				});
		},
		approveOrder() {
			this.changeOrderStatus('Accepted');
		},
		printJobsheet() {
			const doc = new jsPDF();
			const pageWidth = doc.internal.pageSize.width;
			const margin = 15;
			let yPos = margin;

			// Set default font
			doc.setFont('helvetica', 'normal');

			// Add header
			doc.setFillColor(240, 240, 240);
			doc.rect(0, 0, pageWidth, 20, 'F');
			doc.setFontSize(20);
			doc.setFont('helvetica', 'bold');
			doc.text(`ORDER #${this.order.order_id}`, pageWidth / 2, 15, { align: 'center' });
			doc.setDrawColor(200, 200, 200);
			doc.line(margin, 22, pageWidth - margin, 22);
			yPos = 30;

			// Company Info Section
			const img = new Image();
			img.src = varslogo;
			img.onload = () => {
				doc.addImage(img, 'PNG', pageWidth - 60, yPos, 50, 20);
				yPos += 25;

				doc.setFontSize(10);
				doc.text("VARS", margin, yPos);
				doc.text("1-2 Calder Ct, Blackpool, FY4 2RH", margin, yPos + 5);
				doc.text("Blackpool, UK | Tel: +44 7899809168", margin, yPos + 10);
				yPos += 25;

				// Order Summary Table
				const summaryData = [
					["Order Date:", this.formatDate(this.order.order_date), "Status:", this.order.status],
					["Est. Completion:", this.formatDate(this.order.estimated_completion_date), "Total:", `£${this.order.total_price}`],
					["Placed By:", this.order.admin_user_email, "Fulfilled By:", this.order.fulfillment_emails[0]],
					["Email Sent:", this.order.email_sent ? 'Yes' : 'No', "", ""]
				];

				doc.setFont('helvetica', 'bold');
				doc.setFontSize(14);
				doc.text("Order Summary", margin, yPos);
				yPos += 8;
				doc.setFontSize(10);
				doc.setFont('helvetica', 'normal');

				summaryData.forEach(row => {
					doc.text(row[0], margin, yPos);
					doc.text(row[1], margin + 40, yPos);
					doc.text(row[2], margin + 100, yPos);
					doc.text(row[3], margin + 140, yPos);
					yPos += 7;
				});
				yPos += 10;

				// Site Information Box
				doc.setFillColor(245, 245, 245);
				doc.roundedRect(margin, yPos, pageWidth - 2 * margin, 40, 3, 3, 'F');
				doc.setFont('helvetica', 'bold');
				doc.text("Site Information", margin + 10, yPos + 8);
				doc.setFont('helvetica', 'normal');
				doc.setFontSize(10);
				doc.text(`Reference: ${this.order.site.reference}`, margin + 10, yPos + 16);
				doc.text(`Name: ${this.order.site.name}`, margin + 10, yPos + 24);
				doc.text(`Max Stay: ${this.order.site.max_stay} mins`, margin + 80, yPos + 16);
				doc.text(`No Return: ${this.order.site.no_return} mins (${this.order.site.no_return / 60} hrs)`, margin + 80, yPos + 24);
				yPos += 45;

				// Products Header
				doc.setFont('helvetica', 'bold');
				doc.setFontSize(14);
				doc.text("Products & Services", margin, yPos);
				yPos += 10;

				// Products Table Header
				doc.setFillColor(230, 230, 230);
				doc.rect(margin, yPos, pageWidth - 2 * margin, 8, 'F');
				doc.setFontSize(10);
				doc.text("Product ID", margin + 5, yPos + 6);
				doc.text("Description", margin + 40, yPos + 6);
				doc.text("Qty", margin + 130, yPos + 6);
				doc.text("Price", margin + 150, yPos + 6);
				doc.text("Subtotal", margin + 175, yPos + 6);
				yPos += 12;

				// Product Items
				doc.setFont('helvetica', 'normal');
				this.order.order_items.forEach((item, index) => {
					if (yPos + 20 > doc.internal.pageSize.height - margin) {
						doc.addPage();
						yPos = margin;
					}

					doc.setFontSize(10);
					doc.text(item.product_id.toString(), margin + 5, yPos + 6);
					doc.text(`${item.product.name} - ${item.product.description}`, margin + 40, yPos + 6);
					doc.text(item.quantity.toString(), margin + 130, yPos + 6);
					doc.text(`£${item.price}`, margin + 150, yPos + 6);
					doc.text(`£${item.subtotal}`, margin + 175, yPos + 6);

					doc.setDrawColor(220, 220, 220);
					doc.line(margin, yPos + 10, pageWidth - margin, yPos + 10);
					yPos += 12;
				});

				// Comments Section
				if (this.order.comment) {
					yPos += 10;
					doc.setFont('helvetica', 'bold');
					doc.text("Additional Comments:", margin, yPos);
					doc.setFont('helvetica', 'normal');
					const splitComments = doc.splitTextToSize(this.order.comment, pageWidth - 2 * margin - 10);
					splitComments.forEach(line => {
						yPos += 7;
						if (yPos > doc.internal.pageSize.height - margin) {
							doc.addPage();
							yPos = margin;
						}
						doc.text(line, margin + 10, yPos);
					});
				}

				// Footer
				const footerY = doc.internal.pageSize.height - 20;
				doc.setDrawColor(200, 200, 200);
				doc.line(margin, footerY - 5, pageWidth - margin, footerY - 5);
				doc.setFontSize(8);
				doc.text("Thank you for your business!", pageWidth / 2, footerY, { align: 'center' });
				doc.text(`Generated: ${new Date().toLocaleDateString()}`, margin, footerY);
				doc.text("Page 1 of 1", pageWidth - margin, footerY, { align: 'right' });

				// Save PDF
				doc.save(`Jobsheet_${this.order.order_id}.pdf`);
			};
		}
	}
};
</script>

<style scoped>
.container {
	max-width: 1200px;
}

.card-header {
	background-color: #007bff;
	color: white;
}

.card-body {
	padding: 20px;
}

.btn {
	margin: 5px;
}

.product-details {
	border: 1px solid #ddd;
	padding: 10px;
	margin-bottom: 10px;
}

.status-badge {
	padding: 5px;
	border-radius: 5px;
	color: white;
}

.status-square {
	display: inline-block;
	width: 10px;
	height: 10px;
	margin-right: 5px;
	border-radius: 2px;
}

.status-dropdown {
	margin-top: 10px;
}

.page-input {
	width: 60px;
	display: inline-block;
	margin-left: 10px;
	padding: 5px;
	text-align: center;
}

.green-button {
	background-color: #28a745;
	/* Bootstrap's success color */
	border-color: #28a745;
	color: white;
}

.green-button:hover {
	background-color: #218838;
	/* Darker shade for hover */
	border-color: #1e7e34;
}

.order-card {
	width: 100%;
}

.site-info h6 {
	margin-bottom: 10px;
	/* Space below the heading */
	color: white;
	/* Blue color for the heading */
}

.site-info p {
	margin: 0;
	/* Remove default margin */
	color: white;
	/* Dark grey color for the text */
}

.order-list {
	gap: 1.5rem;
}

.card {
	border: none;
	transition: transform 0.2s;
}

.card:hover {
	transform: translateY(-2px);
}

.bg-primary-gradient {
	background: linear-gradient(135deg, #007bff, #0062cc);
}

.status-indicator {
	width: 12px;
	height: 12px;
	border-radius: 3px;
	display: inline-block;
}

.product-item {
	border-left: 3px solid #007bff;
	transition: all 0.2s;
}

.product-item:hover {
	transform: translateX(5px);
}

.site-info {
	background-color: #f8f9fa;
	border: 1px solid white;
}

.fw-semibold {
	font-weight: 600;
}

.cursor-pointer {
	cursor: pointer;
}

.text-uppercase {
	letter-spacing: 0.05em;
}

.btn-sm {
	padding: 0.35rem 0.75rem;
	font-size: 0.875rem;
}

.bg-light-subtle {
	background-color: transparent;
}
</style>